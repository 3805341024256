import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { NOTIFICATION_ID } from '../../../constants/components'
import { fromDateFormatter } from '../../../helpers/time'
import { NotificationsContext } from '../../../contexts/NotificationsContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { NotificationsModalContext } from '../../../contexts/NotificationsModalContext'
import { useModalAccessibility } from '../../../hook/useModalAccessibility'
import { NotificationItem } from '../../atoms/NotificationItem'
import { ShowMoreButton } from '../../atoms/ShowMoreButton'
import { SVGIcon } from '../../atoms/SVGIcon'
import { theme } from '../../../styles'

const notificationsShowMoreId = 'notifications-show-more-id'

export function NotificationsModal(): JSX.Element {
  const { events } = useRouter()
  const modalRef = useRef(null)
  const { isOpen, setIsOpenNotificationsModal } = useContext(NotificationsModalContext)
  const { hit } = useContext(TagCommanderContext)
  const [lastSeenTimeMemo, setLastSeenTimeMemo] = useState(null)
  const { notifications, wordings, fetchNotifications, lastSeenTime, updateLastSeenTime } =
    useContext(NotificationsContext)

  const now = new Date()

  useEffect(function fetchNotificationsOnMount() {
    // Fetch notifications on mount
    // Update notifications from notifications context
    fetchNotifications()
  }, [])

  function handleRouteChange() {
    // Close modal when route change
    setIsOpenNotificationsModal(false)
  }

  function toggleNotification() {
    setIsOpenNotificationsModal()
  }

  function hitClickTMS(index: number) {
    hit(
      {
        screen_clickableElementName: 'notification_liste-article',
        screen_position: `1,${index + 1}`,
      },
      { isClickEvent: true },
    )
  }

  function hitClickCloseTMS() {
    hit(
      {
        screen_clickableElementName: 'notification_fermeture',
      },
      { isClickEvent: true },
    )
  }

  function handleCloseModalClick() {
    setIsOpenNotificationsModal(false)
    hitClickCloseTMS()
  }

  const { inert } = useModalAccessibility({
    show: isOpen,
    ref: modalRef.current,
    onEscape: handleCloseModalClick,
  })

  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])

  useEffect(() => {
    if (isOpen) {
      updateLastSeenTime()
    } else {
      setLastSeenTimeMemo(lastSeenTime)
    }
  }, [isOpen])

  return (
    <>
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-heading"
        className="NotificationsModal__Wrapper flex"
        ref={modalRef}
        // Prevent focus if not open
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inert={inert}
      >
        <input
          className="NotificationsModal_Input"
          id={NOTIFICATION_ID}
          type="checkbox"
          autoComplete="off"
          tabIndex={-1}
          checked={isOpen}
          onChange={toggleNotification}
        />
        <label htmlFor={NOTIFICATION_ID} className="NotificationsModal__BackDrop" />
        <div className="NotificationsModal">
          <header className="NotificationsModal__Header flex flex-column justify-end items-center">
            <button
              data-first-focus
              className="NotificationsModal__Close"
              title="Fermer la modale"
              onClick={handleCloseModalClick}
            >
              <SVGIcon
                name="close"
                size={32}
                primaryColor={theme.cssVars.white}
                secondaryColor={theme.cssVars.white}
              />
            </button>
            <p
              role="heading"
              aria-level={1}
              id="modal-heading"
              className="NotificationsModal__Title"
              dangerouslySetInnerHTML={{ __html: wordings?.title || '' }}
            />
          </header>
          {!notifications.length ? (
            <div className="NotificationsModal__Placeholder flex flex-column items-center">
              <div className="NotificationsModal__Placeholder__Icon">
                <SVGIcon name="bell" size={40} primaryColor={theme.cssVars.white} />
              </div>
              <div className="NotificationsModal__Placeholder__Content">
                {wordings?.placeholder?.subtitleFirstLine ? (
                  <div className="NotificationsModal__Placeholder__Content__FirstLine">
                    {wordings?.placeholder?.subtitleFirstLine}
                  </div>
                ) : null}
                {wordings?.placeholder?.subtitleSecondLine ? (
                  <div className="NotificationsModal__Placeholder__Content__SecondLine">
                    {wordings?.placeholder?.subtitleSecondLine}
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <input type="checkbox" id={notificationsShowMoreId} className="Hidden_Input" />
              <ul className="NotificationsModal__List flex flex-column">
                {notifications.map((notification, index) => {
                  const date = new Date(notification.date)
                  const isNew = lastSeenTimeMemo ? new Date(lastSeenTimeMemo) < date : true
                  const isFirstItem = index === 0
                  const previousItemDate = !isFirstItem
                    ? new Date(notifications[index - 1]?.date)
                    : null
                  const needsDateSeparator =
                    previousItemDate?.getDate() !== date.getDate() &&
                    now.getDate() !== date.getDate()
                  const formattedDate = needsDateSeparator ? fromDateFormatter(date) : null

                  return (
                    <Fragment key={notification.id}>
                      {needsDateSeparator ? (
                        <li
                          className={`NotificationsModal__Separator${
                            index > 4 ? ' NotificationsModal__Item--hidden' : ''
                          }`}
                        >
                          <time dateTime={`${date}`}>{formattedDate}</time>
                        </li>
                      ) : null}
                      <li
                        className={`NotificationsModal__Item${
                          isNew ? ' NotificationsModal__Item--new' : ''
                        }${index > 4 ? ' NotificationsModal__Item--hidden' : ''}`}
                        key={notification.id}
                        onClick={() => hitClickTMS(index)}
                      >
                        <NotificationItem {...notification} />
                      </li>
                    </Fragment>
                  )
                })}
                <ShowMoreButton
                  title={wordings?.showMore || ''}
                  id="NotificationsModal__ShowMoreButton"
                  htmlFor={notificationsShowMoreId}
                  icon="chevron"
                  iconOrientation="down"
                />
              </ul>
            </>
          )}
        </div>
      </div>

      <style jsx global>{`
        html {
          ${isOpen ? 'overflow: hidden;' : ''};
        }
      `}</style>

      <style jsx>{`
        .NotificationsModal__BackDrop {
          display: block;
          opacity: 0;
        }

        #${NOTIFICATION_ID}:checked ~ .NotificationsModal__BackDrop {
          display: block;
          background-color: ${theme.cssVars.backDropColor};
          z-index: 8999;
          width: 100vw;
          height: 100%;
          opacity: 1;
          transition: all 0.5s ease-in;
        }

        .NotificationsModal__Wrapper {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
        }

        .NotificationsModal {
          z-index: 9000;
          max-width: 395px;
          background: ${theme.cssVars.deepBlue};
          position: fixed;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100%;
          overflow-y: scroll;
          transform: translateX(100%);
          transition: transform 0.5s ease-in-out;
        }

        .NotificationsModal_Input {
          display: none;
          visibility: hidden;
        }

        #${NOTIFICATION_ID}:checked ~ .NotificationsModal {
          transform: translateX(0%);
        }

        .NotificationsModal__Header {
          position: sticky;
          top: 0;
          background-color: ${theme.cssVars.deepBlue};
          color: ${theme.cssVars.white};
          padding: 30px 30px 10px;
          z-index: 1;
        }

        .NotificationsModal__Title {
          font-size: 38px;
          font-weight: 400;
          line-height: 122%;
          align-self: flex-start;
          margin: 0;
          padding: 0;
        }

        .NotificationsModal__Close {
          cursor: pointer;
          border: none;
          padding: 0;
          background: transparent;
          align-self: flex-end;
          padding-bottom: 10px;
        }

        .NotificationsModal__Placeholder {
          width: 100%;
          color: ${theme.cssVars.white};
          padding: 0 10px 0 20px;
        }

        .NotificationsModal__Placeholder__Icon {
          margin-bottom: 20px;
        }

        .NotificationsModal__Placeholder__Content {
          position: relative;
          padding: 0 10px 0 20px;
          background-image: linear-gradient(
            ${theme.cssVars.white},
            ${theme.cssVars.white} 10px,
            ${theme.cssVars.darkBlue} 10px,
            ${theme.cssVars.darkBlue}
          );
          background-size: 6px 100%;
          background-position: left 0;
          background-repeat: no-repeat;
        }

        .NotificationsModal__Placeholder__Content__FirstLine {
          margin-bottom: 20px;
          font-weight: 900;
          font-size: 22px;
        }

        .NotificationsModal__Placeholder__Content__SecondLine {
          font-weight: 600;
          font-size: 19px;
        }

        .NotificationsModal__List {
          list-style: none;
          color: ${theme.cssVars.white};
          padding: 0;
          margin: 0 0 40px;
        }

        .NotificationsModal__Item {
          position: relative;
          margin: 0 30px 30px;
          padding: 0 0 0 20px;
          background-image: linear-gradient(
            ${theme.cssVars.white},
            ${theme.cssVars.white} 10px,
            ${theme.cssVars.darkBlue} 10px,
            ${theme.cssVars.darkBlue}
          );
          background-size: 6px 100%;
          background-position: left 63px;
          background-repeat: no-repeat;
        }

        .NotificationsModal__Item::before {
          content: '•';
          color: ${theme.cssVars.white};
          position: absolute;
          top: 0;
          left: -5px;
          font-size: 40px;
        }

        .NotificationsModal__Item--new {
          background-image: linear-gradient(${theme.cssVars.red}, ${theme.cssVars.red});
        }

        .NotificationsModal__Item--new::before {
          color: ${theme.cssVars.red};
        }

        .NotificationsModal__Separator {
          color: ${theme.cssVars.white};
          font-family: ${theme.cssVars.overpass};
          font-size: 36px;
          font-weight: 700;
          line-height: 122%;
          text-transform: capitalize;

          margin: 30px 0;
          padding: 8px 0 8px 26px;
        }

        .Hidden_Input,
        .NotificationsModal__Item--hidden {
          display: none;
          visibility: hidden;
        }

        .NotificationsModal__List :global(#NotificationsModal__ShowMoreButton) {
          display: none;
          visibility: hidden;
        }

        .NotificationsModal__List
          .NotificationsModal__Item:nth-child(6)
          :global(~ #NotificationsModal__ShowMoreButton) {
          display: flex;
          visibility: visible;
          margin-top: 30px;
        }

        #${notificationsShowMoreId}:checked
          ~ .NotificationsModal__List
          .NotificationsModal__Item--hidden {
          display: block;
          visibility: visible;
        }

        #${notificationsShowMoreId}:checked
          ~ .NotificationsModal__List
          :global(#NotificationsModal__ShowMoreButton) {
          display: none;
          visibility: hidden;
        }
      `}</style>
    </>
  )
}
